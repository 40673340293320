<template>
  <div class="form-group mt-4 mb-4" >
    <h1 class="header__title txt-comic mt-2 mb-4">¡Bienvenido de nuevo!</h1>
    <p class="header__description"> Ingresa tu correo para continuar tu aventura y jugar por más premios: </p>
    <label for="">Correo electrónico</label>
    <input class="form-control" placeholder="Ingresa tu correo electrónico" type="email" v-model="user.email" :class="{ error: v$.user.email.$errors.length }">
    <div class="pre-icon os-icon os-icon-user-male-circle"></div>

    <!-- error message -->
    <div class="input-errors general-register__paragraph error_message text-start" v-for="(error, index) of v$.user.email.$errors" :key="index">
      <div class="error-msg">{{ error.$message }}</div>
    </div>
  </div>

  <button class="btn form-btn btn btn-success w-100 mb-5"
          @click.prevent="submitRequest"
          :disabled="isBusy">

    <span class=""> CONTINUAR </span>
    <span v-if="isBusy" class="text-center">
      <span class="spinner-border spinner-border-sm" role="status"></span>
    </span>
  </button>
</template>

<script nonce>
import Modal from '@mints-components/cali-modal/App.vue';
import useVuelidate from "@vuelidate/core";
import {email$, required$, validPassword} from "@helpers/validator_provider";
import { useUserStore } from './email-store';

export default {
  name: 'ContactLogin',
  inject: ['$axios'],
  components:{ Modal },
  setup() {
    return { v$: useVuelidate() }
  },

data: () => ({
    user: {},
    isBusy: false,
    forgotPass: false,
    email: '',
  }),
  computed: {
    computedValidations() {
      return { user: { email: { required$, email$ } } } 
    },
  },
  methods: {
    submitRequest(){
      this.v$.$validate().then(value => {
        if (value) this.sendMagicLink();
      });
    },
    sendMagicLink(){
      const userStore = useUserStore();
      this.isBusy = true;
      this.$axios.post(`/api/v1/login/send-magic-link`, {
        data: this.user
      }).then((r)=>{
        userStore.setUser(this.user.email);
      }).catch(e => {
        this.displayErrorMessage();
      }).finally(() => {
          this.isBusy = false;
      });
    },
    displayErrorMessage() {
      this.$swal.fire({
        icon: 'error',
        title: '¡Espera!',
        text: 'Parece que el correo no coincide con nuestros registros, por favor, verifique que esté bien escrito',
        showConfirmButton: true
      });
    },
  },
  validations() {
      return this.computedValidations;
  }
}
</script>
<style lang="scss" scoped>
@import '@mints-components/cali-dynamic-form/styles/errors';
</style>
import RegisterForm from '@session-components/RegisterSection.vue';
import LoginModal from '@components/header-login/LoginModal.vue';
import HeaderLogin from '@components/header-login/App.vue';
import BannerButton from '@session-components/BannerButton.vue';
import { generateApplication } from '@helpers/generate_component.js';
import { generateAxiosInstance } from '@helpers/axios_provider.js';
import { autoAnimatePlugin } from "@formkit/auto-animate/vue"
import { createPinia } from 'pinia'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import loginMixin from '@mixins/loginMixin'
import sessionLogic from '@session-components/session-logic'

const pinia = createPinia()
const axios = generateAxiosInstance()
const locale = 'es'

const injections = [
  {
    name: '$axios',
    module: axios
  },
  {
    name: '$contact',
    module: typeof contact !== 'undefined' ? contact : null
  },
  {
    name: 'locale',
    module: locale
  },
  {
    name: '$controller',
    module: controller
  }
]

const mixins = [ loginMixin, sessionLogic ]

// HeaderDynamicButton
// Creates a dynamic link or profile button
generateApplication(HeaderLogin, "#header-dynamic-link", {
  packages: [VueSweetalert2, pinia],
  injections,
  props: { textColor: '#638ccc' },
  mixins: mixins
})

// Button in banner
generateApplication(BannerButton, "#btn-banner", {
  injections,
  packages: [pinia]
})

// Button in prizes
generateApplication(BannerButton, "#btn-prizes", {
  injections,
  packages: [pinia]
})

generateApplication(RegisterForm, "#register-form-entry",{
  injections, packages: [VueSweetalert2, autoAnimatePlugin, pinia],
  props: {
    content: contentRegister,
    sectionData: sectionData,
    id: '1'
  }
})

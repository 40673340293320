<template>
<div class="background-page">
<div class="container">
  <div class="row mt-5">
      <div class="reg-left d-flex align-items-end col-12 col-sm-12 col-md-12 col-lg-5 order-2 order-lg-1">
        <img src="https://premiosdeoro-16905.kxcdn.com/public-assets/reg-left-min" class="img-bottom img-fluid mx-auto"/>
      </div>
      <div class="reg-right col-12 col-sm-12 col-md-12 col-lg-7 order-1 order-lg-2">
        <div v-if="!userStore.getUserState()" class="py-5">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link" :class="{ active: !activeTab }" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">
          ¡Tu primera partida te espera!
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" :class="{ active: activeTab }" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">
          Continúa tu aventura
        </button>
      </li>
    </ul>

    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade" :class="{ 'show active': !activeTab }" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
        <section :id="id" class="overflow-visible position-relative d-flex flex-column align-items-center">
          <div class="d-flex justify-content-center  p-0">
            <div class="register-form p-3 mx-0 w-100">
              <DynamicForm :data="content"></DynamicForm>
              <div class="hr-on-form my-4"></div>
              <!-- <div class="d-flex flex-column justify-content-center align-items-center gap-3">
                <span class="text-white w-100 text-center" style="font-size: 17pt; font-weight: 700">¿Ya te registraste anteriormente?</span>
                <OpenModal></OpenModal>
              </div> -->
            </div>
          </div>
        </section>
      </div>
      <div class="tab-pane fade register-form p-3 mx-0 w-100" :class="{ 'show active': activeTab }" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
        <LoginWithPassword></LoginWithPassword>
      </div>
    </div>
 </div>

 <div v-else class=" container__email-send container">
  <div class="row mb-4">
    <div class="tab-pane fade register-form p-3 mx-0 w-100 active show mt-3 mb-5 my-lg-5 pb-5 txt-magic">
    <div class="email-send__info">
      <h4 class="text-center txt-comic my-4 txt-blue header__title txt-comic mt-2 mb-4">¡Correo enviado!</h4>
      <p class="text-center">
        Hemos enviado tu enlace de acceso a <br>
       <strong class="txt-blue"> {{ userStore.getUserEmail() }}</strong><br>
        Revisa tu correo y continúa sumando puntos 
      </p>
      <button id="btn-banner" class="btn form-btn btn-success d-block mx-auto" @click.prevent="sendMagicLink" :disabled="isLoading || isButtonDisabled">
        <span class="">  {{ isButtonDisabled ? `Espera ${remainingTime}s` : "REENVIAR" }}</span>
        <span v-if="isLoading" class="text-center">
          <span class="spinner-border spinner-border-sm" role="status"></span>
        </span>
      </button>
    </div>
    </div>
  </div>
 </div>
      </div>
  </div>
</div>
</div>   
</template>

<!-- En este componente incrustaremos el componente de tabs de bootstrap -->

<script>
import OpenModal from "@components/header-login/openModal.vue";
import DynamicForm from "@mints-components/cali-dynamic-form/App.vue";
import sessionLogic from "@session-components/session-logic";
import ContactLogin from "./ContactLogin.vue";
import { useUserStore } from "./email-store";
import { email } from "@vuelidate/validators";
import LoginWithPassword from "@components/header-login/LoginWithPassword.vue";

export default {
  name: "RegisterSection",
  components: {LoginWithPassword, DynamicForm, OpenModal, ContactLogin},
  mixins: [sessionLogic],
  props: {
    content: Object,
    sectionData: Object, //Este debe de ser el section wrapper
    id: String
  },
  data() {
    return {
      path: '',
      isLoading: false,
      isButtonDisabled: false,
      remainingTime: 30,
      timer: null,

    }
  },
  created() {
    this.path = window.location.pathname;
  },
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  computed: {
    activeTab() {
      return this.path == '/iniciar-sesion';
    }
  },
  methods: {
    sendMagicLink(){
      const userStore = useUserStore();
      this.isLoading = true;
      this.$axios.post(`/api/v1/login/send-magic-link`, {
        data: { email: userStore.getUserEmail()}
      }).then((r)=>{
        this.displaySuccessMessage(userStore.getUserEmail())
      }).catch(e => {
        this.displayErrorMessage();
      }).finally(() => {
          this.isLoading = false;
      });

      this.isButtonDisabled = true;
      this.remainingTime = 30;

      this.timer = setInterval(() => {
        this.remainingTime -= 1;
        if (this.remainingTime <= 0) {
          this.isButtonDisabled = false;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    displayErrorMessage() {
      this.$swal.fire({
        icon: 'error',
        title: '¡Espera!',
        text: 'Parece que hubo un problema, por favor, intentelo de nuevo más tarde.',
        showConfirmButton: true
      });
    },
    displaySuccessMessage(email) {
      this.$swal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: `Se ha reenviado un email a ${email}.`,
        showConfirmButton: true
      });
    },
    handleSubmit() {
          this.isButtonDisabled = true;
          this.remainingTime = 30;

        this.timer = setInterval(() => {
          this.remainingTime -= 1;
          if (this.remainingTime <= 0) {
            this.isButtonDisabled = false;
            clearInterval(this.timer);
          }
        }, 1000);
    }
  }
}
</script>

<style scoped lang="scss">
.background-page {
  background-image: url('https://premiosdeoro-16905.kxcdn.com/public-assets/background-azulclaro');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: end;
}

</style>
<template>
  <div class="login-component">
    <!-- Header -->
    <div class="header">
      <p class="modal-title pl-3 w-100 text-center txt-comic mt-4 mb-3 txt-blue" style="font-size: 18pt">
        {{ forgotPass ? 'Restablece tu contraseña' : 'Iniciar sesión' }}
      </p>
    </div>

    <!-- Body -->
    <div class="body">
      <div v-if="forgotPass">
        <p class="description mb-4 text-center">
          El restablecimiento de tu contraseña será posible siempre y cuando se tenga acceso al correo y ya se haya registrado previamente.
        </p>
        <div class="form-group">
          <label for="">Correo electrónico</label>
          <input
              class="form-control"
              placeholder="Ingresa tu correo electrónico"
              type="email"
              v-model="user.email"
              :class="{ error: v$.user.email.$errors.length }"
          />
          <div class="pre-icon os-icon os-icon-user-male-circle"></div>
          <!-- error message -->
          <div
              class="input-errors general-register__paragraph error_message text-start"
              v-for="(error, index) of v$.user.email.$errors"
              :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <button class="px-3 pt-3 mx-auto text-light-green" @click="resetPassLayout" href="/reset-password">
          Iniciar sesión con contraseña
        </button>
        <div class="footer">
          <button
              class="btn form-btn w-100 btn btn-success my-4"
              @click.prevent="submitRequest"
              :disabled="isBusy"
          >
            <span>ENVIAR</span>
            <span v-if="isBusy" class="text-center">
            <span class="spinner-border spinner-border-sm" role="status"></span>
          </span>
          </button>
        </div>
      </div>
      <div v-else>
          <form @submit.prevent="submitLogin" class="pl-3 pr-3">
            <p class="description mb-4 w-100 text-center">
              Escribe tu correo electrónico y contraseña para iniciar sesión
            </p>
            <div class="form-group">
              <div class="my-2">
                <label class="general-register__paragraph align-self-start">Correo electrónico</label>
                <input
                    class="form-control"
                    placeholder="Ingresa tu correo electrónico"
                    type="email"
                    v-model="user.email"
                    :class="{ error: v$.user.email.$errors.length }"
                />
                <!-- error message -->
                <label
                    class="general-register__paragraph error_message text-start"
                    v-for="(error, index) of v$.user.email.$errors"
                    :key="index"
                >
                  <p class="error-msg mb-0">{{ error.$message }}</p>
                </label>
              </div>
              <div>
                <label class="general-register__paragraph align-self-start">Contraseña</label>
                <input
                    class="form-control"
                    placeholder="Ingresa tu contraseña"
                    type="password"
                    autocomplete="current-password"
                    v-model="user.password"
                    :class="{ error: v$.user.password.$errors.length }"
                />
                <label class="general-register__paragraph error_message text-start">
                  <p class="error-msg mb-0" v-for="(error, index) of v$.user.password.$errors" :key="index">
                    {{ error.$message }}
                  </p>
                </label>
              </div>
            </div>
            <button class="px-3 pt-3 mx-auto text-light-green" type="button" @click="resetPassLayout" href="/reset-password">
              ¿Olvidaste tu contraseña?
            </button>
            <div class="footer">
              <button
                  class="btn form-btn w-100 btn btn-success my-4"
                  type="submit"
                  :disabled="isBusy"
              >
                <span>INGRESAR</span>
                <span v-if="isBusy" class="text-center">
                  <span class="spinner-border spinner-border-sm" role="status"></span>
                </span>
              </button>
            </div>
          </form>
        </div>
    </div>
  </div>
</template>

<script nonce>
import useVuelidate from '@vuelidate/core';
import { email$, required$, validPassword } from '@helpers/validator_provider';

export default {
  name: 'LoginComponent',
  inject: ['locale', '$axios'],
  setup() {
    return { v$: useVuelidate() };
  },
  data: () => ({
    user: {},
    isBusy: false,
    forgotPass: false,
    email: '',
  }),
  computed: {
    modalSuccessText() {
      return `Te hemos enviado un correo a "${this.user.email}", recuerda revisar tu carpeta de spam si es que no encuentras este correo.`;
    },
    modalErrorText() {
      return !this.forgotPass
          ? `Parece que el correo o la contraseña no coinciden con nuestros registros`
          : `Parece que el correo no coincide con nuestros registros, por favor, verifique que esté bien escrito`;
    },
    computedValidations() {
      return this.forgotPass
          ? { user: { email: { required$, email$ } } }
          : { user: { email: { required$, email$ }, password: { required$, ...this.passwordValidation } } };
    },
    passwordValidation() {
      return {
        passwordValid: {
          $validator: validPassword,
          $message: 'La contraseña debe contener entre 6 y 16 caracteres',
        },
      };
    },
  },
  methods: {
    submitLogin() {
      this.v$.$validate().then((value) => {
        if (value) this.sendLogin();
      });
    },
    submitRequest() {
      this.v$.$validate().then((value) => {
        if (value) this.sendMagicLink();
      });
    },
    sendLogin() {
      this.isBusy = true;
      this.$axios
          .post('/api/v1/login', {
            data: this.user,
          })
          .then((r) => {
            window.location.href = "/juego";
          })
          .catch((e) => {
            this.displayErrorMessage();
          })
          .finally(() => {
            this.isBusy = false;
          });
    },
    sendMagicLink() {
      this.$axios
          .post(`/api/v1/login/send-magic-link`, {
            data: this.user,
          })
          .then((r) => {
            this.email = this.user.email;
            this.$swal.fire({
              icon: 'success',
              title: '¡Enviado!',
              text: this.modalSuccessText,
              showConfirmButton: true,
            });
          })
          .catch((e) => {
            this.displayErrorMessage();
          })
          .finally(() => {
            this.isBusy = false;
          });
    },
    displayErrorMessage() {
      this.$swal.fire({
        icon: 'error',
        title: '¡Espera!',
        text: this.modalErrorText,
        showConfirmButton: true,
      });
    },
    hide() {
      // Removed modal hide logic
      this.user = { email: null, password: null };
      this.v$.$reset();
    },
    resetPassLayout() {
      this.forgotPass = !this.forgotPass;
    },
  },
  validations() {
    return this.computedValidations;
  },
};
</script>

<style lang="scss" scoped>
.text-light-green {
  color: #5BB8B5;
  text-decoration: underline;
}
@import '@mints-components/cali-dynamic-form/styles/errors';
.terms-input {
  display: block;
  width: 100% !important;
}
</style>

<template>
  <template v-if="loadingContactSession">
    <span class="spinner-border spinner-border-sm" :style="{ color: textColor + '!important' }" role="status"></span>
  </template>
  <template v-else>
    <template v-if="contactHasSession">
        <div :class="['btn-group', dropdownClass, 'dropdown-profile']" class="me-2" style="position: relative;">
          <button
              type="button"
              class="btn nav-link dropdown-toggle d-flex justify-content-center align-items-center fw-bolder"
              :class="{'circle-menu': circleMenu }"
              data-bs-toggle="dropdown"
              aria-expanded="false">
            {{ circleMenu ? initials: 'Mi perfil'  }}
          </button>
          <ul class="dropdown-menu profile-options" :style="dropdownMenuStyle">
            <li class="dropdown-item-text">
              <span>{{ contactName }}<br/>{{ contactMail }}</span>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="/perfil/puntajes">Mis puntajes</a></li>
            <li><a class="dropdown-item" @click.prevent="logout" style="cursor:pointer">Cerrar sesión</a></li>
          </ul>
        </div>
    </template>
    <template v-else>
      <a
          class="nav-link d-flex align-items-center"
          aria-current="page"
          style="cursor: pointer"
          :style="{ color: textColor }"
          href="/iniciar-sesion">
        Iniciar sesión
      </a>
    </template>
  </template>
</template>

<script>
export default {
  name: 'LoginLink',
  inject: ['locale'],
  props: {
    textColor: {
      type: String,
      default: 'red'
    }
  },
  data() {
    return {
      dropdownClass: 'dropdown',
      circleMenu: true
    };
  },
  computed: {
    initials() {
      if (!this.contactName) return '';
      const [firstName, lastName] = this.contactName.split(' ');
      const nameInitial = firstName ? firstName.charAt(0).toUpperCase() : '';
      const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : '';
      return `${nameInitial}${lastInitial}`;
    },
    dropdownMenuStyle() {
      return this.dropdownClass === 'dropdown'
          ? {position: 'absolute', top: '100%', left: 0, zIndex: 1055}
          : {position: 'absolute', top: 0, right: '100%', zIndex: 1055};
    }
  },
  created() {
    this.searchContactSession();
  },
  mounted() {
    this.updateDropdownClass();
    window.addEventListener('resize', this.updateDropdownClass);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateDropdownClass);
  },
  methods: {
    updateDropdownClass() {
      // Use a media query to toggle between dropdown and dropstart based on screen width
      this.circleMenu = window.innerWidth >= 992;
      this.dropdownClass = window.innerWidth >= 992 ? 'dropstart' : 'dropdown';
    },
  }
}
</script>

<style scoped>

.circle-menu {
  aspect-ratio: 1 / 1;
  padding: 1px;
  border-radius: 50%;
  border: 3px solid #446EC0;
  color: #446EC0!important;
  max-width: 40px;
}

.check-icon {
  position: absolute;
  top: 10%;
  left: 100%;
  transform: translate(-50%, -50%);
  font-size: 15px; /* Adjust icon size as needed */
  z-index: 1;
}

.dropdown-profile {
  align-items: start; /* Position the dropdown button to the left */
}
.profile-options {
  font-size: 14px !important;
  background-color:  #446EC0 !important;
  color: white;
  .dropdown-item {
    color: white;
    font-size: 15px !important;
    &:hover {
      background-color: #C0E5F0 !important;
      color:  #446EC0 !important;
    }
  }
}
</style>

import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    userEmail: null,
    isLoggedIn: false,
  }),
  actions: {
    setUser(email) {
      this.userEmail = email;
      this.isLoggedIn = true;
    },
    getUserEmail() {
      return this.userEmail;
    },
    getUserState() {
      return this.isLoggedIn;
    }
  }
});
<template>
  <template v-if="loadingContactSession">
    <div class="btn btn-banner btn-promo mx-auto"> <span class="spinner-border spinner-border-sm" role="status"></span></div>
  </template>
  <template v-else>
    <template v-if="contactHasSession" >
      <a class="btn btn-banner btn-promo mx-auto" href="/juego">Jugar</a>
    </template>
    <template v-else>
      <a class="btn btn-banner btn-promo mx-auto text-nowrap" href="/registro">Registrarme</a>
    </template>
  </template>
</template>

<script>
import SessionLogic from '@session-components/session-logic'
import {useContactSessionStore} from "@session-components/session-store";
export default {
  name: "BannerButton",
  methods: {useContactSessionStore},
  mixins: [SessionLogic]
}
</script>